import { Collapse } from 'antd';
import SitePage from './sitepage';

const MediateurPage = () => {
    const { Panel } = Collapse;
    return (
        <SitePage titre="Le Médiateur">
            <Collapse defaultActiveKey={[ '1' ]}>
                <Panel header="Mission du Médiateur" key="1">
                    <p>Le Médiateur a pour mission le traitement à l’amiable des litiges nés d’un contrat d’assurance opposant un particulier à une entreprise d’assurance.</p>
                </Panel>
                <Panel header="Pouvoirs du Médiateur" key="2">
                    <p>Le Médiateur est responsable de la conduite de sa mission et dispose des moyens et pouvoirs nécessaires pour solliciter des parties tous documents ou renseignements indispensables pour mener à bien sa mission. Le Médiateur peut demander aux parties prenantes de lui communiquer tous documents qu’il estime utiles pour l’accomplissement de sa mission.</p>
                </Panel>
                <Panel header="Champs de compétence" key="3">
                    <p>Sont éligibles à l’examen du Médiateur, les litiges survenus dans le cadre d’un contrat d’assurances ou d’assistance souscrit auprès d’une entreprise membre de la FMSAR. </p>
                    <p>Le Médiateur ne peut être saisi que pour les litiges dont le montant est estimé à 5000 dirhams au moins pour les remboursements maladie et à 2.000 DH pour les autres litiges. </p>
                    <p>Les litiges peuvent concerner les prestations d’assurance, la gestion du contrat et généralement toute contestation survenant dans l’application du contrat d’assurance ou d’assistance.</p>
                </Panel>
            </Collapse>
        </SitePage>
    );
};

export default MediateurPage;
