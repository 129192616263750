import styled from 'styled-components';
import { Row, Col, Tag } from 'antd';
import { couleurs } from 'assets/styles/theme';

export const SChamp = styled(Row)`
  padding:10px 7px 3px 7px;
  border-bottom:1px solid #EEE;
  .ant-form-item{
    margin:0;
  }
`;

export const SLibelle = styled(Col)`
      color:#AAA;
      font-weight: 700;
      font-size: 13px;
      padding-left:5px;
      height:100%;
`;

export const SValeur = styled(Col)`
      color:#444;
      font-weight: 500;
      font-size: 14px;
      text-align:left;
    white-space: break-spaces;
`;

export const SValeurNoWrap = styled(Col)`
     color:#444;
      font-weight: 500;
      font-size: 14px;
      white-space:nowrap ;
`;

export const STitre = styled(Row)`
 padding: 5px 0 5px 10px;
 background-color: ${couleurs.principale};
  border-bottom:1px solid #EEE;
      color:#fff;
      font-weight: 700;
      font-size: 14px;
`;

export const STag = styled(Tag)`
  margin:5px 10px 2px 10px;
  & span{
    white-space: break-spaces;
  }
`;
