import { Carousel, Col, Row } from 'antd';
import './styles.less';

const HeaderCarousel = () => {
    return (
        <Carousel autoplay>
            <div className="carouselPart">
                <Row className="carouselContent">
                    <Col span={13} className="carouselText">
                        <div className="carouselHighText">Le Médiateur a pour mission de </div>
                        <div className="carouselHighText">favoriser le réglement amiable </div>
                        <div className="carouselHighText">de votre litige avec votre </div>
                        <div className="carouselHighText">assureur</div>
                    </Col>
                    <Col span={11} className="carouselImage2" />
                </Row>
            </div>
            <div className="carouselPart">
                <Row className="carouselContent">
                    <Col span={12} className="carouselText">
                        <div className="carouselHighText">L&rsquo;avis du Médiateur</div>
                        <div className="carouselHighText">Ne s&rsquo;impose pas au Parties</div>
                        <div className="carouselSubText">Le Médiateur n&rsquo;est pas un juge</div>
                    </Col>
                    <Col span={12} className="carouselImage3" />
                </Row>
            </div>
            <div className="carouselPart">
                <Row className="carouselContent">
                    <Col span={13} className="carouselText">
                        <div className="carouselHighText">Vous avez un différent avec votre</div>
                        <div className="carouselHighText">assureur et le litige persiste?</div>
                        <div className="carouselSubText">Le Médiateur de l&rsquo;assurance est là pour vous aider</div>
                    </Col>
                    <Col span={11} className="carouselImage1" />
                </Row>
            </div>
        </Carousel>
    );
};

export default HeaderCarousel;
