export const messages = {
    DATA_ERROR_ALERT: 'Internal Error',
};

//export const APP_BASE_URL = process?.env.PUBLIC_URL || '';
export const APP_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_TIME_FORMAT = 'HH:mm';
export const API_CALL_TIMEOUT = 1 * 60 * 1000;
export const API_URL = 'https://api.mediateurassurance.ma:8181';

export const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

export const APP = {
    NAME: 'Le Médiateur de l assurance',
};
