import { Button } from 'antd';
import SitePage from './sitepage';
import { SH3, SH4 } from './styles';

const CommentPage = () => {
    return (
        <SitePage titre="Comment saisir le médiateur">
            <SH3>Par courrier à</SH3>
            <div style={{ fontWeight: 'bold' }}>LE MEDIATEUR DE L’ASSURANCE</div>
            <p>154, Bd d&rsquo;Anfa Casablanca</p>
            <p>
                Pour faciliter la prise en charge de votre demande, votre dossier doit comprendre
                <SH4>impérativement :</SH4>
            </p>
            <ul>
                <li>Les coordonnées de la personne concernée et son adresse.</li>
                <li>Le nom de l&rsquo;entreprise d’assurance concernée.</li>
                <li>Un résumé du différend et les échanges de courriers avec l’assureur relatifs au litige</li>
                <li>Le courrier de votre assureur indiquant sa position définitive.</li>
                <li>La ou les copies du (des) contrat(s) concerné(s) par votre litige.</li>
                <li>Tous les documents que vous jugez utiles pour l&rsquo;étude de votre dossier</li>
            </ul>
            <p style={{ fontWeight: 'bold' }}>N&rsquo;envoyez pas d&rsquo;originaux, des copies suffisent.</p>
            <SH3>Voie électronique en premier et courrier ensuite</SH3>
            <Button type="primary">Saisir le Médiateur</Button>
        </SitePage>
    );
};

export default CommentPage;
