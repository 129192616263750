import { UploadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Upload from 'antd/lib/upload/Upload';
import axios from 'axios';
import DialogueConfirmation from 'composants/dialogue/dialogue.confirmation';
import Formulaire, { ChampTexteLong } from 'composants/formulaire';
import { APP_DATE_FORMAT } from 'config/constants.config';
import { useAppDispatch, useAppSelector } from 'config/store.config';
import { IEtatDemandeHist } from 'modules/etatDemandeHist/etatDemandeHist.modele';
import EtatDemandeHistService from 'modules/etatDemandeHist/etatDemandeHist.service';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IDemande } from './demande.modele';

const DialogueComplementInformation = ({ visible, setVisible }) => {
    const dispatch = useAppDispatch();
    const demande: IDemande = useAppSelector((state) => state.demandeState.demande);
    const [form] = useForm<IEtatDemandeHist>();
    const [formFile] = useForm();
    const [uploadFiles, setUploadFiles] = useState([]);
    const [messageErreur, setMessageErreur] = useState(null);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            formFile.resetFields();
            setMessageErreur(null);
            setUploadFiles([]);
        }
    }, [visible]);

    const completerInformation = () => {
        form.validateFields().then(() => {
            const etatHist = { ...form.getFieldsValue(), etatDemande: { id: 2 }, libelle: moment().format(APP_DATE_FORMAT), demande, _type: 'etatDemandeHist' };
            dispatch(EtatDemandeHistService.creer(etatHist));
            uploadFiles.forEach((b, index) => {
                const formData = new FormData();
                const d = new Date();
                const fileBlob = new Blob([b.data], { type: b.type });
                if (fileBlob.size > 2000000) {
                    notification['error']({ message: 'Le fichier ' + b.libelle + ' n’est pas valide car sa taille dépasse la limite permise de 2Mo.' });
                } else {
                    formData.append('file', fileBlob, 'attachement' + index);
                    formData.append('libelle', b.libelle);
                    formData.append('key', '' + d.getTime() + '' + index);
                    formData.append('fileContentType', b.type);
                    formData.append('ownerType', 'demande');
                    formData.append('ownerId', '' + demande.id);
                    axios({
                        method: 'post',
                        url: 'api/pbl/media',
                        data: formData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                }
            });
        });
    };

    const fermerDialog = () => {
        setVisible(false);
    };

    const beforeUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setUploadFiles([...uploadFiles, { data: reader.result, type: file.type, libelle: file.name }]);
        };
        reader.readAsArrayBuffer(file);
        return false;
    };

    return (
        <DialogueConfirmation titre={'Complément d’informations'} visible={visible} actionAnnuler={fermerDialog} actionConfirmer={completerInformation} nomActionConfirmer="Confirmer">
            <div style={{ color: 'red' }}>{messageErreur}</div>
            <Formulaire form={form}>
                <ChampTexteLong nom="commentaire" requis />
            </Formulaire>
            <Formulaire form={formFile}>
                <div className="ant-form-item-label">
                    <label style={{ fontWeight: '700' }}>Ajouter d&rsquo;autres pièces à l’instruction du dossier</label>
                </div>
                <Upload beforeUpload={beforeUpload}>
                    <Button icon={<UploadOutlined />}>Joindre un fichier</Button>
                </Upload>
            </Formulaire>
        </DialogueConfirmation>
    );
};

export default DialogueComplementInformation;
