import { Button } from 'antd';
import { useNavigate } from 'react-router';
import SitePage from './sitepage';

const SiteHomePage = () => {
    const navigate = useNavigate();
    return (
        <SitePage titre="MOT DE BIENVENUE">
            <p>La médiation est un processus amiable de rechercher de solution à un différend par l&rsquo;intermédiaire d&rsquo;un tiers indépendant : le médiateur.</p>
            <p>Le cadre de la médiation institionnelle en assurances est fixé par la Charte de médiation signée par les entreprises membres de la Fédération Marocaine des Sociétés d&rsquo;Assurances et de Réassurance et l&rsquo;autorité de tutelle</p>
            <p>Le médiateur de l&rsquo;assurance est désigné par un “Comité de nommination et de suivi” composé de deux représentant de la Fédération Marocaine des Sociétés d&rsquo;Assurances et de Réassurance et de deux représentant de l&rsquo;autorité de contrôle des assurances et de la prévoyance sociale</p>
            <div style={{textAlign:'right',paddingRight:'20px'}}>
                <Button type="primary" onClick={()=>{navigate('/motBienvenue');}}>La Suite</Button>
            </div>
            <div style={{padding:'20px', borderLeft:'5px solid #EEE', fontSize:'17px',wordSpacing:'3px',lineHeight:'28px'}}>
            Bienvenue sur le site du Médiateur de l&rsquo;Assurance. Le nouveau dispositif de médiation vous permet de traiter rapidement et gratuitement le litige que vous avez avec un assureur en ayant recours à l&rsquo;intervention d&rsquo;une instance indépendante permettant d&rsquo;aboutir amiablement à la solution recherchée.
            </div>
        </SitePage> 
    );
};

export default SiteHomePage;
