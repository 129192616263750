import SitePage from './sitepage';

const MotBienvenuePage = () => {

    return (
        <SitePage titre="MOT DE BIENVENUE">
            <p>La médiation est un processus amiable de rechercher de solution à un différend par l&rsquo;intermédiaire d&rsquo;un tiers indépendant : le médiateur.</p>
            <p>Le cadre de la médiation institionnelle en assurances est fixé par la Charte de médiation signée par les entreprises membres de la Fédération Marocaine des Sociétés d’Assurances et de Réassurance et l’autorité de tutelle</p>
            <p>Le médiateur de l’assurance est désigné par un “Comité de nommination et de suivi” composé de deux représentant de la Fédération Marocaine des Sociétés d’Assurances et de Réassurance et de deux représentant de l&rsquo;autorité de contrôle des assurances et de la prévoyance sociale</p>
            <p>Pour faire connaitre ce nouveau dispositif, la charte de médiation invite les entreprises à informer leurs assurés de l’existence du médiateur et des modalités de saisine.</p>
            <p>Sont éligibles à l’examen du médiateur, les litiges concernant les personnes physiques ou morales et survenus dans le cadre d’un contrat d’assurance lorsque le montant estimé est supérieur ou égal à 2.000 DH. Ce seuil est porté à 5.000 DH pour les sinistres relevant de l’assurance maladie</p>
            <p>L’assureur accepte d’office le processus de médiation, dès lors qu’une réclamation est jugée recevable par le médiateur. Toutefois, lorsqu’il s’agit d’une personne morale autre qu’une très petite entreprise (TPE), il est loisible à l’assureur de refuser la médiation.</p>
            <p>&ldquo;On entend par TPE, toute entreprise qui emploie moins de 5 salariés et réalise un chiffre d’affaires d’affaire inférieur à 3 millions DH.&ldquo;</p>
            <p>Il convient de préciser que le médiateur n’est ni un expert ni un conseiller, sa mission consiste à instaurer une communication entre les parties pour favoriser l’émergence d’une solution.</p>
            <p>Cependant, si, en dépit de ces efforts, les parties n’arrivent pas à s’entendre, le médiateur peut emettre un avis tendant à solutionner le litige.</p>
            <p>Enfin, le recours au médiateur est gratuit.</p>
        </SitePage> 
    );
};

export default MotBienvenuePage;
