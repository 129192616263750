import ActeursPage from 'domaines/site/acteurs';
import ChartePage from 'domaines/site/charte';
import CommentPage from 'domaines/site/comment';
import ContactPage from 'domaines/site/contact';
import DemandePage from 'domaines/site/demande';
import SiteHomePage from 'domaines/site/home';
import LitigesPage from 'domaines/site/litiges';
import MediateurPage from 'domaines/site/mediateur';
import MediationPage from 'domaines/site/mediation';
import MotBienvenuePage from 'domaines/site/motBienvenue';
import ProcessusPage from 'domaines/site/processus';
import QuandPage from 'domaines/site/quand';
import SaisieDemandePage from 'domaines/site/saisieDemande';
import SuivreDemandePage from 'domaines/site/suivreDemande';
import PublicAppLayout from 'layout/public';
import LightLayout from 'layout/public/lightLayout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageNotFound from './error.404';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/demande" element={<LightLayout />}>
                    <Route path="saisie" element={<SaisieDemandePage />} />
                    <Route path="suivi" element={<SuivreDemandePage />} />
                    <Route path="suivi/:renvoi" element={<SuivreDemandePage />} />
                </Route>
                <Route path="/" element={<PublicAppLayout />}>
                    <Route path="/" element={<SiteHomePage />} />
                    <Route path="/mediateur" element={<MediateurPage />} />
                    <Route path="/mediation" element={<MediationPage />} />
                    <Route path="/charte" element={<ChartePage />} />
                    <Route path="/processus" element={<ProcessusPage />} />
                    <Route path="/acteurs" element={<ActeursPage />} />
                    <Route path="/demande" element={<DemandePage />} />
                    <Route path="/litiges" element={<LitigesPage />} />
                    <Route path="/quand" element={<QuandPage />} />
                    <Route path="/comment" element={<CommentPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/motBienvenue" element={<MotBienvenuePage />} />
                    <Route path="*" element={<PageNotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
export default AppRoutes;
