import { PaperClipOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { decode } from 'base-64';
import { useAppDispatch, useAppSelector } from 'config/store.config';
import _ from 'lodash';
import { IMedia } from 'modules/media/media.modele';
import MediaService from 'modules/media/media.service';
import { useEffect } from 'react';
import { IDemande } from './demande.modele';

const ListePieceJointe = () => {
    const dispatch = useAppDispatch();
    const listeMedia: IMedia[] = useAppSelector((state) => state.mediaState.listeMedia);
    const demande: IDemande = useAppSelector((state) => state.demandeState.demande);

    useEffect(() => {
        if (!_.isEmpty(demande)) {
            dispatch(MediaService.lister({ ownerId: demande.etatDemandeHist.id, ownerType: 'etatDemandeHist' }));
        }
    }, [dispatch, demande]);

    const afficherFichier = (item) => {
        const base64str = item.file;
        const binary = decode(base64str.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        const blob = new Blob([view], { type: 'application/pdf' });

        //const blob = decode(item.file);
        const url = URL.createObjectURL(blob);

        window.open(url);
    };

    return (
        <div>
            {listeMedia.map((item, index) => {
                return (
                    <div key={index}>
                        <Button type="link" icon={<PaperClipOutlined />} onClick={() => afficherFichier(item)}>
                            {item.libelle}
                        </Button>
                    </div>
                );
            })}
        </div>
    );
};

export default ListePieceJointe;
