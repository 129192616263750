import { Row, Col } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { SChamp, SLibelle, SValeur, STitre, STag } from './styles';
import labels from 'util/labelMapping';
import _ from 'lodash';
import { CheckSquareFilled, CloseSquareOutlined } from '@ant-design/icons';
export { default as Champ } from './champ';
export { default as Titre } from './titre';
export { default as Tag } from './tag';

const Etat = ({ entite = null, nombreColonne = 1, children }) => {
    const [ listeElement, setListeElement ] = useState([]);

    const getLibelle = useCallback((cprops): any => {
        if (cprops.libelle) {
            return cprops.libelle;
        } else if (cprops.nom) {
            const i = cprops.nom.indexOf('.');
            if (i > 0) {
                return labels.get(cprops.nom.substr(0, i)) + ( (cprops.nom.substr(i + 1) === 'code' || cprops.nom.substr(i + 1) === 'nom' || cprops.nom.substr(i + 1) === 'libelle') ? '' : ' ' + labels.get(cprops.nom.substr(i + 1)));
            } else {
                return labels.get(cprops.nom);
            }
        } else {
            return 'ND';
        }
    }, []);

    const getTexte = useCallback(
        (cprops): any => {
            if (cprops.texte) {
                return cprops.texte;
            } else if (cprops.nom && entite) {
                const i = cprops.nom.indexOf('.');
                if (i > 0) {
                    const a = entite[cprops.nom.substr(0, i)];
                    return a ? a[cprops.nom.substr(i + 1)] : 'ND';
                } else {
                    return entite[cprops.nom];
                }
            } else {
                return 'ND';
            }
        },
        [ entite ]
    );

    useEffect(
        () => {
            if (!_.isNil(entite) && !_.isEmpty(entite)) {
                const liste = [];
                const colWidth = 24 / nombreColonne;
                React.Children.forEach(children, (c, index) => {
                    if(!c.props.cache){
                        if (c.type.name === 'Titre') {
                            liste.push(
                                <Col span={colWidth} key={index}>
                                    <SChamp>
                                        <STitre span={20}>{c.props.libelle}</STitre>
                                    </SChamp>
                                </Col>
                            );
                        } else if (c.type.name === 'Tag') {
                            liste.push(
                                <Col span={colWidth} key={index}>
                                    <SChamp>
                                        <SLibelle span={10}>{getLibelle(c.props)}</SLibelle>
                                        <STag color="#FFD124">{getTexte(c.props)}</STag>
                                    </SChamp>
                                </Col>
                            );
                        } else if (c.type.name === 'ChampBooleen') {
                            liste.push(
                                <Col span={colWidth} key={index}>
                                    <SChamp>
                                        <SLibelle span={10}>{getLibelle(c.props)}</SLibelle>
                                        {getTexte(c.props)===true?<SValeur span={14} style={{color:'green'}}><CheckSquareFilled/></SValeur>:<SValeur span={14}><CloseSquareOutlined /></SValeur>}
                                    </SChamp>
                                </Col>
                            );
                        } else {
                            liste.push(
                                <Col span={colWidth} key={index}>
                                    <SChamp>
                                        <SLibelle span={10}>{getLibelle(c.props)}</SLibelle>
                                        <SValeur span={14}>{getTexte(c.props)}</SValeur>
                                    </SChamp>
                                </Col>
                            );
                        }
                    }
                });
                setListeElement(liste);
            }
        },
        [ children, entite, nombreColonne, getLibelle, getTexte ]
    );

    return <Row gutter={10}>{listeElement}</Row>;
};

export default Etat;
