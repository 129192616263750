import { createAsyncThunk } from '@reduxjs/toolkit';
import {  IDemande, IRequeteDemande } from './demande.modele';
import axios from 'axios';
import { serviceOptions } from 'util/notificationMiddleWare';
 


const recupererPourSuivi = createAsyncThunk('demande/recupererPourSuivi', async (requeteDemande:IRequeteDemande) => {
    const response = await axios.post('api/pbl/demande/suivi', {nom:requeteDemande.demandeur.libelle,prenom:requeteDemande.demandeur.prenom,libelle:requeteDemande.libelle});
    return (response.data  && response.data.length>0 )? response.data[0]:null;
});


const recupererDemandeur = createAsyncThunk('demande/recupererDemandeur', async (demandeurId:string) => {
    const response = await axios.get(`api/pbl/demandeur/${demandeurId}`);
    return response.data;
});




const creer = createAsyncThunk('demande/creer', async (demande: IDemande) => {
    const resultat = await axios.post('api/pbl/demande', demande);
    return resultat.data;
}, serviceOptions);
 

const DemandeService = {
    recupererPourSuivi,
    recupererDemandeur,
    creer,
};

export default DemandeService;
