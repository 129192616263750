import { CaretRightOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Upload from 'antd/lib/upload/Upload';
import axios from 'axios';
import { Bloc } from 'composants';
import AfficherPremier from 'composants/conteneur/afficherPremier';
import Encadre from 'composants/conteneur/encadre';
import DialogueConfirmation from 'composants/dialogue/dialogue.confirmation';
import DialogueInformation from 'composants/dialogue/dialogue.information';
import Formulaire, { ChampDate, ChampEmail, ChampReference, ChampTel, ChampTexte, ChampTexteLong } from 'composants/formulaire';
import { useAppDispatch, useAppSelector } from 'config/store.config';
import { IDemande } from 'modules/demande/demande.modele';
import DemandeService from 'modules/demande/demande.service';
import { DemandeStore } from 'modules/demande/demande.store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import EtatDemande from './etatDemande';
import SitePage from './sitepage';
import { SH4 } from './styles';

const SaisieDemandePage = () => {
    const dispatch = useAppDispatch();
    const [form] = useForm<IDemande>();
    const [premiereAutorisation, setPremiereAutorisation] = useState(false);
    const [secondeAutorisation, setSecondeAutorisation] = useState(false);
    const [enCoursAcaps, setEnCoursAcaps] = useState(false);
    const [enCoursAutreJuridiction, setEnCoursAutreJuridiction] = useState(false);
    const [objetLitigeSinistre, setObjetLitigeSinistre] = useState(false);
    const demandeCree: IDemande = useAppSelector((state) => state.demandeState.demandeCree);
    const [success, setSuccess] = useState(false);
    const [validerDemandeVisible, setValiderDemandeVisible] = useState(false);
    const navigate = useNavigate();
    const [uploadFiles, setUploadFiles] = useState([]);

    useEffect(() => {
        form.resetFields();
        dispatch(DemandeStore.initialiser());
    }, []);

    useEffect(() => {
        if (demandeCree && demandeCree.id) {
            form.resetFields();
            uploadFiles.forEach((b, index) => {
                const formData = new FormData();
                const d = new Date();
                const fileBlob = new Blob([b.data], { type: b.type });
                if (fileBlob.size > 2000000) {
                    notification['error']({ message: 'Le fichier ' + b.libelle + ' n est pas valide car sa taille dépasse la limite permise de 2Mo. ' });
                } else {
                    formData.append('file', fileBlob, 'attachement' + index);
                    formData.append('libelle', b.libelle);
                    formData.append('key', '' + d.getTime() + '' + index);
                    formData.append('fileContentType', b.type);
                    formData.append('ownerType', 'etatDemandeHist');
                    formData.append('ownerId', '' + demandeCree.etatDemandeHist.id);
                    axios({
                        method: 'post',
                        url: 'api/pbl/media',
                        data: formData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                        .then(function (response) {
                            console.log(response);
                        })
                        .catch(function (response) {
                            console.log(response);
                        });
                }
            });
            setSuccess(true);
        }
    }, [demandeCree]);

    const objetLitigeChanged = (value) => {
        setObjetLitigeSinistre(value?.libelle?.toUpperCase() === 'SINISTRE');
    };

    const enregistrer = () => {
        form.validateFields()
            .then((values) => {
                setValiderDemandeVisible(true);
            })
            .catch((errorInfo) => {
                console.log(form.getFieldsValue());
                console.log(errorInfo);
            });
    };
    const confirmerValidation = () => {
        setValiderDemandeVisible(false);
        dispatch(DemandeService.creer({ ...form.getFieldsValue(), enCoursAcaps, enCoursAutreJuridiction }));
    };
    const annulerValidation = () => {
        setValiderDemandeVisible(false);
    };

    const confirmerSucces = () => {
        setSuccess(false);
        dispatch(DemandeService.recupererPourSuivi(demandeCree));
        navigate('/demande/suivi/renvoi');
    };

    const beforeUpload = (file) => {
        const reader = new FileReader();

        reader.onload = () => {
            setUploadFiles([...uploadFiles, { data: reader.result, type: file.type, libelle: file.name }]);
        };
        reader.readAsArrayBuffer(file);

        return false;
    };

    return (
        <SitePage titre="Saisir le Médiateur">
            <div>Ce formulaire est destiné uniquement à la saisine du Médiateur et non au suivi de votre dossier.</div>
            <Bloc style={{ padding: '20px 20px 0 0 ' }}>
                <Encadre titre="identification.assurance">
                    <Formulaire form={form} nombreColonne={2}>
                        <ChampReference nom="compagnie" reference="compagnie" requis="true" libelle="Compagnie d’assurance" />
                        <ChampTexte nom="numeroPolice" requis="true" libelle="Numéro de Police d’assurance" />
                        <ChampReference nom="branche" reference="branche" requis="true" libelle="Branche d’assurance" />
                        <ChampTexte nom="souscripteur" requis="true" />
                        <ChampTexte nom="assure" requis="true" libelle="Assuré" />
                        <ChampReference nom="objetLitige" reference="objetLitige" requis="true" libelle="Objet du litige" siChange={objetLitigeChanged} />
                    </Formulaire>
                </Encadre>
            </Bloc>
            <Bloc style={{ padding: '20px 20px 0 0 ' }}>
                <Encadre titre="identification.demandeur">
                    <Formulaire form={form} nombreColonne={2}>
                        <ChampReference nom="demandeur.typeDemandeur" requis="true" reference="typeDemandeur" libelle="Vous êtes" />
                        <ChampReference nom="demandeur.civilite" requis="true" reference="civilite" libelle="Civilité" />
                        <ChampTexte nom="demandeur.libelle" requis="true" libelle="Nom" />
                        <ChampTexte nom="demandeur.prenom" requis="true" libelle="Prénom" />
                        <ChampTexte nom="demandeur.adresse" requis="true" />
                        <ChampReference nom="demandeur.ville" requis="true" reference="ville" />
                        <ChampTel nom="demandeur.telephone" requis="true" />
                        <ChampEmail nom="demandeur.email" requis="true" />
                    </Formulaire>
                </Encadre>
            </Bloc>
            <Bloc style={{ padding: '20px 20px 0 0 ' }}>
                <Encadre titre="identification.litige">
                    <AfficherPremier si={objetLitigeSinistre}>
                        <Formulaire form={form} nombreColonne={2}>
                            <ChampDate nom="dateSinistre" requis="true" />
                            <ChampTexte nom="numeroSinistre" requis="true" />
                            <ChampReference nom="natureSinistre" requis="true" reference="natureSinistre" />
                            <ChampReference nom="natureLitige" requis="true" reference="natureLitige" />
                            <ChampTexte nom="montantReclamation" requis="true" libelle="Montant de la réclamation" />
                            <ChampTexteLong nom="descriptionLitige" surTouteLaLigne requis="true" libelle="Description détaillée du Litige" />
                        </Formulaire>
                        <Formulaire form={form} nombreColonne={2}>
                            <ChampTexte nom="montantReclamation" requis="true" libelle="Montant de la réclamation" />
                            <ChampTexteLong nom="descriptionLitige" surTouteLaLigne requis="true" libelle="Description détaillée du Litige" />
                        </Formulaire>
                    </AfficherPremier>
                    <SH4 style={{ margin: '10px 0 5px 0 ' }}>Pièces nécessaire à l’instruction de mon dossier</SH4>
                    <Formulaire form={form}>
                        <Upload beforeUpload={beforeUpload}>
                            <Button icon={<UploadOutlined />}>Joindre un fichier</Button>
                        </Upload>
                    </Formulaire>
                </Encadre>
            </Bloc>
            <Bloc style={{ padding: '20px 20px 0 0 ' }}>
                <Encadre titre="identification.autorisation">
                    <div>
                        <strong>Avez-vous une procèdure en cours pour ce litige</strong>
                    </div>
                    <div>
                        <Checkbox checked={enCoursAcaps} onChange={(e) => setEnCoursAcaps(e.target.checked)} /> <CaretRightOutlined /> Auprès de l&rsquo;ACAPS
                    </div>
                    <div>
                        <Checkbox checked={enCoursAutreJuridiction} onChange={(e) => setEnCoursAutreJuridiction(e.target.checked)} /> <CaretRightOutlined /> Auprès d&rsquo;une juridiction
                    </div>
                    <br />
                    <div>Conformément la loi 09- 08 édictant des mesures de protection des données à caractère personnel, </div>
                    <div>
                        <Checkbox checked={premiereAutorisation} onChange={(e) => setPremiereAutorisation(e.target.checked)} /> <CaretRightOutlined />
                        <strong>J&rsquo;autorise </strong>expressément l&rsquo;entreprise d&rsquo;assurance indiquée ci-dessus à communiquer au Médiateur tous les documents ou informations utiles à l&rsquo;accomplissement de sa mission de Médiation concernant ma présente demande accompagnée d&rsquo;une copie de toutes les pièces et documents relatifs au litige.
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Checkbox checked={secondeAutorisation} onChange={(e) => setSecondeAutorisation(e.target.checked)} /> <CaretRightOutlined />
                        <strong>J&rsquo;autorise</strong> également le médiateur à communiquer ces pièces et documents à l&rsquo;entreprise d&rsquo;assurance précitée.
                    </div>
                </Encadre>
            </Bloc>
            <div style={{ paddingTop: '20px' }}>
                <Button type="primary" onClick={enregistrer} disabled={!premiereAutorisation || !secondeAutorisation}>
                    Valider
                </Button>
            </div>
            <DialogueInformation visible={success} action={confirmerSucces} nomAction="confirmer" titre="Demande Créée Avec Success" entete="Veuillez noter le numero de votre demande afin de faire son suivi">
                <div style={{ color: '#0093AF' }}>{demandeCree.libelle}</div>
            </DialogueInformation>

            <DialogueConfirmation visible={validerDemandeVisible} actionConfirmer={confirmerValidation} actionAnnuler={annulerValidation} largeur={1200} titre="Confirmer Envoi Demande" entete="Veuillez confirmer l'envoi de la demande avec les informations suivantes:">
                <EtatDemande demande={{ ...form.getFieldsValue(), enCoursAcaps, enCoursAutreJuridiction }} objetLitigeSinistre={objetLitigeSinistre} />
            </DialogueConfirmation>
        </SitePage>
    );
};

export default SaisieDemandePage;
