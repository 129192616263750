import Icon from '@ant-design/icons';
import { Row } from 'antd';
import { SAvatar, SCorps, SDialog, SEntete } from './styles';

const DialogueConfirmation = ({ visible, titre, icon = null, largeur = 600, nomActionConfirmer = 'Confirmer', actionConfirmer, nomActionAnnuler = 'Annuler', actionAnnuler, entete = null, children }) => {
    const getTitre = () => {
        return (
            <span>
                {icon && <SAvatar shape="circle" src={<Icon component={icon} />} size={32} />}
                {titre}
            </span>
        );
    };

    const getEntete = () => {
        if (entete) {
            return (
                <Row>
                    <SEntete span="24">{entete}</SEntete>
                </Row>
            );
        }
    };

    return (
        <SDialog visible={visible} onOk={actionConfirmer} okText={nomActionConfirmer} onCancel={actionAnnuler} cancelText={nomActionAnnuler} closable={false} title={getTitre()} width={largeur}>
            {getEntete()}
            <Row>
                <SCorps span="24">{children}</SCorps>
            </Row>
        </SDialog>
    );
};
export default DialogueConfirmation;
