import { Button, Col, Menu, Row } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import brand from 'assets/images/brand.svg';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const LayoutHeader = () => {
    const navigate = useNavigate();
    const items = [
        { key: 'home', label: <Link to="">ACCUEIL</Link> },
        { key: 'mediateur', label: <Link to="/mediateur">LE MEDIATEUR</Link> },
        {
            key: 'mediation',
            label: <Link to="/mediation">LA MEDIATION</Link>,
            children: [
                { key: 'charte', label: <Link to="/charte">LA CHARTE</Link> },
                { key: 'processus', label: <Link to="/processus">LE PROCESSUSS</Link> },
                { key: 'acteurs', label: <Link to="/acteurs">LES ACTEURS</Link> },
            ],
        },
        {
            key: 'demande',
            label: 'DEMANDE DE MEDIATION',
            children: [
                { key: 'litiges', label: <Link to="/litiges">LITIGES TRAITES</Link> },
                { key: 'quand', label: <Link to="/quand">QUAND SAISIR LE MEDIATEUR</Link> },
                { key: 'comment', label: <Link to="/comment">COMMENT SAISIR LE MEDIATEUR</Link> },
            ],
        },
        { key: 'contact', label: <Link to="/contact">CONTACT</Link> },
    ];
    return (
        <Header>
            <div className="header">
                <Row wrap={false}>
                    <Col flex="none" className="logo">
                        <img src={brand} alt="logo" onClick={() => navigate('/')} />
                    </Col>
                    <Col flex="auto">
                        <Row className="actionsWrapper">
                            <Col span={24}>
                                <div>
                                    <Button type="default" onClick={() => navigate('/demande/suivi')}>
                                        Suivre votre demande
                                    </Button>
                                    <Button type="primary" onClick={() => navigate('/demande/saisie')}>
                                        Saisir le Médiateur
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="menuWrapper">
                            <Col span={24}>
                                <Menu items={items} mode="horizontal" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Header>
    );
};

export default LayoutHeader;
